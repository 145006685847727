import React from 'react';
import ResumeData from '../../util/data/resumeData';
import ExperienceCard from '../ExperienceCard/ExperienceCard';

const ExperienceSection = (props) => {
  return (
    <div className="ExperienceCard">
      {ResumeData.map((job, index) => {
        return (
          <div className="ExperienceCardContainer" key={index}>
            <ExperienceCard props={job} />
          </div>
        );
      })}
    </div>
  );
};

export default ExperienceSection;
