import React from 'react';
import './Resume.scss';
import SkillsSection from '../../../../components/SkillsSection/SkillsSection';
import ExperienceSection from '../../../../components/ExperienceSection/ExperienceSection';
import EducationCard from '../../../../components/EducationCard/EducationCard';
import Footer from '../../../../components/Footer/Footer'
import Portfolio from '../../../home/HomeViews/PortfolioPage/Portfolio';

const Resume = () => {
  return (
    <div className="Resume" style={{ minHeight: '75vh' }}>
      <div className="ResumePageContainer">
        <div className="Nameplate">
          <h1>Chiebuka "Chii" Nwachukwu</h1>
        </div>
        <div className="ResumeContainer">
          <div className="experienceContainer">
            <div className="experienceContainerHeader">
              <h1>Experience</h1>
            </div>
            <ExperienceSection />
            <div className="experienceContainerHeader" style={{ borderTop: '1px solid black', paddingTop: '2rem' }}>
              <h1>Education and Training</h1>
            </div>
            <EducationCard
              school="General Assembly DC"
              degree="Software Engineering immersive"
              timeline="Sept. 2019​ - Dec. 2019​"
            />
            <EducationCard
              school="University of Maryland Eastern Shore, Princess Anne, MD"
              degree="Bachelor of Science, Biology"
              timeline="Jan. 2014​ - May 2017​​"
            />

          </div>

          <div className="skillsContainer" >
            <div className="skillsContainerHeader">
              <h1>Skills</h1>
            </div>
            <SkillsSection />
          </div>

        </div>

      </div>

      <Portfolio/>
      <Footer/>
    </div>
  );
};

export default Resume;
