export default [
  {
    name: 'Cotripper',
    description:
      "Cotripper is an open-source full-stack application that facilitates organized group travel experiences for single mothers. My contributions included developing Banner and Modal component libraries and collaborating with a team of five engineers to build out the 'Book a trip' page. Over 25 engineers worked on this project using agile (kanban) methodologies, morning and afternoon standing-up, and GitHub workflow.",
    technologies: ['Django (back-end)', 'Enzyme', 'Material UI', 'PostgreSQL', 'React.JS (Front-end)', 'Storybook'],
    github: 'https://github.com/CotripperPlatform/CoTrip',
    backgroundImage: require('../applicationImages/cotrip.png'),
    deployed: 'https://cotripper.netlify.app/',
    language: ['Javascript', 'Python', 'HTML & CSS'],
    skillsIn: false,
    category: ['Applications'],
    domain: '',
  },
  {
    name: 'Medicx UI',
    description:
      'Created a  User Interface for an application that connects people to medical practitioners across the United States. It allows users to search for medical practitioners using their last names, insurance user ids, and specialties. The application also allows medical practitioners to create, edit, and delete their profiles.',
    technologies: ['MongoDB', 'Express', 'React', 'Node'],
    github: 'https://github.com/cenwachukwu/Medics',
    backgroundImage: require('../applicationImages/medix.png'),
    deployed: 'https://medicx.netlify.com/',
    language: ['Javascript', 'HTML & CSS'],
    skillsIn: false,
    category: ['Applications'],
    domain: '',
  },
  {
    name: 'Medicx API',
    description:
      'Developed an API for an application that connects people to medical practitioners across the United States. It handles the backend functionality that allows users to search for medical practitioners using various parameters while allowing medical practitioners to create, edit, and delete their profiles.',
    technologies: ['Express', 'Heroku', 'MongoDB', 'Mongoose ORM', 'Node'],
    github: 'https://github.com/cenwachukwu/a-food-and-drug-api',
    backgroundImage: require('../applicationImages/medicxjson.png'),
    deployed: 'https://med-directory-cen.herokuapp.com/directory',
    language: ['Javascript'],
    skillsIn: false,
    category: ['Applications'],
    domain: '',
  },
  {
    name: 'Library',
    description:
      'Created and showcased a library of UI components in an isolated development environment using Storybook.',
    technologies: ['Adobe XD', 'React', 'Storybook'],
    github: 'https://github.com/cenwachukwu/A-Component-Library',
    backgroundImage: require('../applicationImages/storybook.png'),
    deployed: 'https://gifted-hamilton-0abe26.netlify.com',
    language: ['Javascript', 'HTML & CSS'],
    skillsIn: false,
    category: ['Applications'],
    domain: '',
  },
  {
    name: 'ChickFlix',
    description:
      'Created a learning application that features flashcard decks and a scoring quiz game using vanilla Javascript.',
    technologies: ['HTML', 'CSS', 'JavaSript'],
    github: 'https://github.com/cenwachukwu/Chick-Flix',
    backgroundImage: require('../applicationImages/chickflix.png'),
    deployed: 'https://chick-flix.netlify.com/',
    language: ['HTML & CSS'],
    skillsIn: false,
    category: ['Applications'],
    domain: '',
  },
  {
    name: 'theNotesCli',
    description:
      "Developed a command-line application that has the ability to create, update and delete notes based on the user's request.",
    technologies: ['Peewee ORM', 'PostgreSQL', 'Python'],
    github: 'https://github.com/cenwachukwu/pythonCLIProject',
    backgroundImage: require('../applicationImages/notes.gif'),
    deployed: false,
    language: ['Python'],
    skillsIn: false,
    category: ['Applications'],
    domain: '',
  },
  {
    name: 'Object Storage',
    description:
      '  Cloud storage is a cloud computing model that enables storing data and files on remote servers and accessed over the internet or through a dedicated private connection. This mechanism represents storage devices that are designed specifically for cloud-based provisioning. ',
    technologies: [
      'Amazon S3',
      'Amazon Route53',
      'Amazon CloudFront',
      'Amazon IAM',
      'Amazon CloudWatch',
      'Amazon CloudTrail',
      'Amazon Lambda',
    ],
    github: 'https://github.com/cenwachukwu/Object-Storage',
    backgroundImage: require('../applicationImages/Add a P (8).png'),
    deployed: false,
    language: [],
    skillsIn: [
      'Understand Amazon S3 services ',
      'Integrating AWS services to secure applications ',
      'Understand the need for regulatory and compliance considerations',
      'Data Storage Management and Confidentiality',
      'Infomation / Data Security',
      'Monitoring and logging ',
      'Secure application access management and data confidentiality',
    ],
    category: ['Portfolio'],
    domain: 'Design Resilient Architectures ',
  },
  {
    name: 'Identity & Access Management',
    description:
      'How do we control and place a limit on information access or disclosure to authorized users and prevent access from unauthorized users? By implementing a security structure built on strong authorization and the principle of least privilege. Here, we grant access as needed, enforce separation of duties, avoid long-term credentials, and provide a user or resource with the privilege that is essential to perform its intended function.',
    technologies: ['Amazon IAM', 'Amazon CloudWatch', 'Amazon CloudTrail'],
    github: 'https://github.com/cenwachukwu/Identity-Access-Management',
    backgroundImage: require('../applicationImages/Add a P (1).png'),
    deployed: 'https://tecknolochii.substack.com/p/identity-and-access-management',
    language: [],
    skillsIn: [
      'Secure application access management and data confidentiality',
      'Infomation / Data Security',
      'Monitoring and logging ',
      'Applying AWS security best practices to IAM users and root users ',
    ],
    category: ['Portfolio'],
    domain: 'Design secure access to AWS resources.',
  },
];
