import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import './App.scss';
import Home from './views/home/home';

import Navbar from './components/Navbar/Navbar';
import Resume from './views/portfolio/views/resume/Resume';
import Applications from './views/portfolio/views/Categories/Categories';
import Contact from './views/contactMe/Contact';
import MobileNavbar from './components/MobileNavbar/MobileNavbar';
import AboutPage from './views/home/HomeViews/AboutPage/About';
import PortfolioPage from './views/portfolio/views/Portfolio/PortfolioPage';
import Categories from './views/portfolio/views/Categories/Categories';
import MobileFooter from './components/MobileFooter/MobileFooter';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <MobileNavbar />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutme" element={<AboutPage />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/applications" element={<Applications />} />
          <Route path="/contactme" element={<Contact />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/category/:category" element={<Categories />} />
        </Routes>
        <MobileFooter />
      </BrowserRouter>
    </div>
  );
}

export default App;
