import React, { useState } from 'react';
import './ContactMe.scss';
import Axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Contactme = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const enabled = firstName.length > 0 && lastName.length > 0 && email.length && message.length;

  const navigate = useNavigate();

  const FORM_ENDPOINT = 'https://public.herotofu.com/v1/01b207f0-ffde-11ec-bc36-e1ea9ccadd33';

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { firstName, lastName, email, message };
    console.log(data);
    Axios.post(FORM_ENDPOINT, data)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          navigate('/');
        }
      })

      .catch((err) => console.log(err));
  };

  const BootstrapButton = styled(Button)({
    width: '100%',
    fontSize: 16,
    padding: '0.8rem',
    backgroundColor: 'rgb(96, 118, 139)',
    borderColor: '#0063cc',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: 'rgba(126, 146, 163, 0.5)',
      borderColor: '#0062cc',
      boxShadow: 'none',
      color: 'black',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(96, 118, 139,.5)',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(96, 118, 139,.5)',
    },
  });

  return (
    <div className="Contactme">
      <div className="ContactmeContainer">
        <form action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST" target="_blank">
          <div className="formName">
            <TextField
              id="standard-helperText"
              type="text"
              label="First Name"
              helperText="Enter your first name"
              variant="filled"
              fullWidth={true}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              value={firstName}
              required
            />
            <TextField
              fullWidth={true}
              type="text"
              id="standard-helperText"
              label="Last Name"
              helperText="Enter your last name"
              variant="filled"
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              value={lastName}
              required
            />
          </div>

          <div className="formEmail">
            <TextField
              fullWidth={true}
              type="email"
              id="standard-helperText"
              label="Email"
              helperText="Enter your email"
              variant="filled"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
              required
            />
          </div>

          <div className="formEmail">
            <TextField
              fullWidth={true}
              id="outlined-multiline-static"
              type="text"
              label="Message"
              helperText=" "
              variant="filled"
              multiline
              rows={4}
              sx={{ textAlign: 'left' }}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
              value={message}
              required
            />
          </div>

          <div className="formButton">
            <BootstrapButton variant="contained" type="submit" value="Submit" disabled={!enabled}>
              Submit
            </BootstrapButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contactme;
