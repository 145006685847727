import React from 'react';
import './SocialIcons.scss';
import ResumePDF from '../../util/data/Resume.pdf';

const SocialIcons = () => {
  return (
    <ul className="socialMedia">
      <li className="socialIcon">
        <a href="mailto:cenwachukwuu@gmail.com" target="_blank" rel="noopener noreferrer">
          <i className="fas fa-envelope"></i>
        </a>
      </li>
      <li className="socialIcon">
        <a href="https://www.linkedin.com/in/cenwachukwu" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin-in"></i>
        </a>
      </li>
      <li className="socialIcon">
        <a href="https://medium.com/@cenwachukwuu" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-medium"></i>
        </a>
      </li>

      {/* <li className="socialIcon">
        <a href="https://codepen.io/cenwachukwu" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-codepen"></i>
        </a>
      </li> */}
      <li className="socialIcon">
        <a href="https://github.com/cenwachukwu" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-github"></i>
        </a>
      </li>
      <li className="socialIcon">
        <a href={ResumePDF} target="_blank" rel="noopener noreferrer">
          <i className="fas fa-file"> Resume</i>
        </a>
      </li>
    </ul>
  );
};

export default SocialIcons;
