import React from 'react';
import Footer from '../../../../components/Footer/Footer';
import './PortfolioPage.scss';
import PortfolioPages from '../../../../components/PortfolioPages/PortfolioPages';

const PortfolioPage = () => {
  return (
    <div className="PortfolioPageContainer">
      <div className="PortfolioPage">
        <PortfolioPages />
      </div>
      <Footer />
    </div>
  );
};

export default PortfolioPage;
