import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../../../components/Footer/Footer';
import './Categories.scss';
import data from '../../../../util/data/applicationData';
import Portfolio from '../../../home/HomeViews/PortfolioPage/Portfolio';

const Categories = () => {
  const { category } = useParams();

  console.log(category);

  return (
    <div className="CategoriesContainer">
      <div className="CategoriesPage">
        {data
          .filter((project) => project.category.includes(category))
          .map((project, index) => {
            // console.log(project);
            const { name, github, backgroundImage, deployed, description, skillsIn, domain, technologies } = project;

            console.log(technologies);

            return (
              <div className="ProjectContainer" key={index}>
                <div className="Project">
                  <div className="ApplicationCard">
                    <div className="ApplicationName">
                      <h2>{name}</h2>
                    </div>

                    <div className="ApplicationImage">
                      <img src={backgroundImage} alt="project" />
                    </div>

                    <div className="ApplicationLinksContainer">
                      <div className="ApplicationLinks">
                        <a href={github} target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-github "></i>
                        </a>
                      </div>
                      {deployed ? (
                        <div className="ApplicationLinks withBorder">
                          <a href={deployed} target="_blank" rel="noopener noreferrer" className="apadding">
                            <i className="fas fa-link"></i>
                          </a>
                        </div>
                      ) : null}
                    </div>

                    {domain ? (
                      <div className="Domain">
                        <h3>{domain}</h3>
                      </div>
                    ) : null}

                    {description ? (
                      <div className="ApplicationDescription">
                        <p>{description}</p>
                      </div>
                    ) : null}

                    <div className="ApplicationSkillsnTech">
                      {technologies ? (
                        <div className="SkillsnTech techBox">
                          <h4>Technology</h4>
                          {technologies.map((technology, index) => {
                            return <p key={index}>{technology}</p>;
                          })}
                        </div>
                      ) : null}

                      {skillsIn ? (
                        <div className="SkillsnTech skillBox">
                          <h4>Skills</h4>
                          {skillsIn.map((skills, index) => {
                            return (
                              <p className="skill" key={index}>
                                {skills}
                              </p>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Portfolio />
      <Footer />
    </div>
  );
};

export default Categories;
