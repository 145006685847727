import React from 'react';
import './EducationCard.scss';

const EducationCard = (props) => {
  const { school, degree, timeline } = props;
  return (
    <div className="EducationCard">
      <div className="ExperienceCardHeader">
        <h2>{school}</h2>
      </div>
      <div className="ExperienceCardPositionTimeline">
        <h3>
          {degree} <span> | {timeline}</span>
        </h3>
      </div>
    </div>
  );
};

export default EducationCard;
