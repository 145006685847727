import React from 'react';
import LandingPage from './HomeViews/LandingPage/LandingPage';
import Footer from '../../components/Footer/Footer';
import Portfolio from './HomeViews/PortfolioPage/Portfolio';

const Home = () => {
  return (
    <div className="Home" style={{ minHeight: '75vh' }}>
      <LandingPage />
      <Portfolio />
      <Footer/>
    </div>
  );
};

export default Home;
