import * as React from 'react';
import './Hamburger.scss';
import Resume from '../../util/data/Resume.pdf';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Divider from '@mui/material/Divider';

import Settings from '@mui/icons-material/Settings';

import Link from '@mui/material/Link';
import { createSvgIcon } from '@mui/material/utils';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ReceiptIcon from '@mui/icons-material/Receipt';

const Hamburger = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const HomeIcon = createSvgIcon(<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />, 'Home');

  return (
    <div className="Hamburger">
      <Tooltip title="Menu">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            sx={{
              width: 65,
              height: 65,
              fontSize: 40,
              color: 'white',
              bgcolor: 'rgb(96, 118, 139)',
              boxShadow: '5',
              marginBottom: '1rem',
            }}
          >
            C
          </Avatar>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <HomeIcon sx={{ fontSize: 35, color: 'rgb(96, 118, 139)' }} />
          <Link href="/" sx={{ color: 'black', textDecoration: 'none', fontWeight: 400, m: 2 }}>
            Home{' '}
          </Link>
        </MenuItem>

        <Divider />

        <MenuItem>
          <AccountCircleOutlinedIcon sx={{ fontSize: 35, color: 'rgb(96, 118, 139)' }} />
          <Link href="/aboutme" sx={{ color: 'black', textDecoration: 'none', fontWeight: 400, m: 2 }}>
            About{' '}
          </Link>
        </MenuItem>

        <Divider />

        <MenuItem>
          <Settings sx={{ fontSize: 35, color: 'rgb(96, 118, 139)' }} />
          <Link href="/portfolio" sx={{ color: 'black', textDecoration: 'none', fontWeight: 400, m: 2 }}>
            Portfolio{' '}
          </Link>
        </MenuItem>

        <Divider />

        <MenuItem>
          <ReceiptIcon sx={{ fontSize: 35, color: 'rgb(96, 118, 139)' }} />
          <Link href={Resume} target="_blank" sx={{ color: 'black', textDecoration: 'none', fontWeight: 400, m: 2 }}>
            Resume{' '}
          </Link>
        </MenuItem>

        <Divider />

        <MenuItem>
          <LocalPhoneIcon sx={{ fontSize: 35, color: 'rgb(96, 118, 139)' }} />
          <Link href="/contactme" sx={{ color: 'black', textDecoration: 'none', fontWeight: 400, m: 2 }}>
            Contact Me{' '}
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Hamburger;
