import React from 'react';
import './SkillsSection.scss';
import SkillsCard from '../SkillsCard/SkillsCard';

const SkillsSection = () => {
  return (
    <div className="SkillsSection">
      <div className="skills">
        <div className="skillsHeader">
          <h2>Cloud Deployment</h2>
        </div>
        <SkillsCard icon="fab fa-aws" name="Amazon Web Services" />
        <SkillsCard icon="fa fa-paper-plane" name="Heroku" />
        <SkillsCard icon="fab fa-docker" name="Docker" />
        <SkillsCard icon="fas fa-cloud" name="Kubernetes" />
      </div>

      <div className="skills">
        <div className="skillsHeader">
          <h2>Programming Languages</h2>
        </div>
        <SkillsCard icon="fab fa-css3" name="CSS" />
        <SkillsCard icon="fab fa-html5" name="HTML" />
        <SkillsCard icon="fab fa-js" name="Javascript" />
        <SkillsCard icon="fab fa-python" name="Python" />
      </div>

      <div className="skills">
        <div className="skillsHeader">
          <h2>Web Technologies</h2>
        </div>
        <SkillsCard icon="fab fa-css3" name="CSS3" />
        <SkillsCard icon="fab fa-html5" name="HTML5" />
        <SkillsCard icon="fab fa-js" name="Javascript" />
        <SkillsCard icon="fab fa-react" name="React JS" />
        <SkillsCard icon="fab fa-js" name="Express" />
        <SkillsCard icon="fab fa-python" name="Django" />
        <SkillsCard icon="fab fa-node-js" name="Node.JS" />
      </div>

      <div className="skills">
        <div className="skillsHeader">
          <h2>ORM Tools</h2>
        </div>
        <SkillsCard icon="fas fa-cloud" name="Mongoose" />
      </div>

      <div className="skills">
        <div className="skillsHeader">
          <h2>Style</h2>
        </div>
        <SkillsCard icon="fab fa-bootstrap" name="Bootstrap" />
        <SkillsCard icon="fa fa-adjust" name="Material UI" />
        <SkillsCard icon="fab fa-sass" name="Sass (CSS preprocessors)" />
      </div>

      <div className="skills">
        <div className="skillsHeader">
          <h2>CI/CD</h2>
        </div>
        <SkillsCard icon="fab fa-jenkins" name="Jenkins" />
        <SkillsCard icon="fab fa-gitlab" name="GitLab" />
      </div>

      <div className="skills">
        <div className="skillsHeader">
          <h2>Content Management</h2>
        </div>
        <SkillsCard icon="fab fa-wordpress-simple" name="WordPress" />
      </div>

      <div className="skills">
        <div className="skillsHeader">
          <h2>User Experience</h2>
        </div>
        <SkillsCard icon="fa fa-search" name="MVC Framework" />
        <SkillsCard icon="fas fa-universal-access" name="Accesibility" />
        <SkillsCard icon="fa fa-desktop" name="Responsive Web Design" />
        <SkillsCard icon="fa fa-sitemap" name="Wireframing" />
      </div>

      <div className="skills">
        <div className="skillsHeader">
          <h2>Methodologies</h2>
        </div>
        <SkillsCard icon="fa fa-calendar-o" name="Agile - Scrum" />
        <SkillsCard icon="fa fa-object-ungroup" name="OOP" />
        <SkillsCard icon="fa fa-server" name="RESTful API" />
      </div>

      <div className="skills">
        <div className="skillsHeader">
          <h2>Additional</h2>
        </div>
        <SkillsCard icon="fas fa-satellite" name="Zendesk" />
        <SkillsCard icon="fab fa-jira" name="Jira" />
        <SkillsCard icon="fa fa-paper-plane" name="Linux, UNIX, MAC OS X" />
        <SkillsCard icon="fab fa-github" name="Github" />
        <SkillsCard icon="fab fa-git" name="Git Version Control" />
        <SkillsCard icon="fas fa-truck-monster" name="Postman" />
        <SkillsCard icon="fas fa-asterisk" name="VS Code" />
        <SkillsCard icon="fa fa-bug" name="Bug & Issue Tracking" />
        <SkillsCard icon="fas fa-vest" name="Adobe XD" />
      </div>
    </div>
  );
};

export default SkillsSection;
