import React from 'react';
import './Portfolio.scss';
import PortfolioPages from '../../../../components/PortfolioPages/PortfolioPages';

const Portfolio = () => {
  return (
    <div className="Portfolio">
      <div className="PortfolioConatiner">
        <div className="PortfolioHeaderConatiner">
          <div className="PortfolioHeaderConatinerborder"></div>
          {/* <div className="Header">
            <h1>Portfolio</h1>
          </div> */}
          <div className="PortfolioHeaderConatinerborder"></div>
        </div>
        <PortfolioPages />
      </div>
    </div>
  );
};

export default Portfolio;
