import React from 'react';
import './Navbar.scss';
import { Link } from 'react-router-dom';
import ResumePDF from '../../util/data/Resume.pdf';

const Navbar = () => {
  return (
    <div className="Navbar">
      <div className="overlayContainer">
        <div className="NavbarContainer">
          <div className="NavLogo">
            <Link to="/">
              <h1>Chii</h1>
            </Link>
          </div>
          <div className="NavLinks">
            <ul>
              <li>
                <Link to="/aboutme">About me</Link>
              </li>
              <li>
                <a href="/portfolio">Portfolio</a>
              </li>
              <li>
                <a href={ResumePDF} target="_blank" rel="noopener noreferrer">
                  Resume
                </a>
              </li>
              <li className="contactLink">
                <Link to="/contactme">Contact me</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
