import React from 'react';
import './MobileNavbar.scss';
import { Link } from 'react-router-dom';
import Hamburger from '../Hamburger/Hamburger';

const MobileNavbar = () => {
  return (
    <div className="MobileNavbar">
      <div className="MobileNavbarContainer">
        <div className="MobileNavbarLogo">
          <Link to="/">
            <h1>chii</h1>
          </Link>
        </div>

        <div className="MobileNavbarHamburger">
          <Hamburger />
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
