import React from 'react';
import './LandingPage.scss';
import image from '../../../../util/images/chii.jpeg';

const LandingPage = () => {
  return (
    <div className="LandingPage" id="home">
      <div className="LandingPageContainer">
        <div className="LandingInfoContainer">
          <div className="ImageSection">
            <div className="ImageContainer">
              <img src={image} alt="Chiebuka Nwachukwu" />
            </div>
          </div>

          <div className="verticalLine"></div>

          <div className="infoContainer">
            <div className="NamePlateContainer">
              <h1>
                Problem solver, innovator, and lifelong learner, passionate about delivering secure cloud solutions.
              </h1>
            </div>

            <div className="mobile">
              <div className="ImageContainer">
                <img src={image} alt="Chiebuka Nwachukwu" />
              </div>
            </div>

            <div className="BioContainer">
              <div className="certication">
                <h2>
                  Solutions Architect | SAF<span>e</span> Product Owner/Product Manager
                </h2>
              </div>
              <p>
                "With my background in biotechnology, customer experience, container security & compliance, and
                designing, implementing, & managing complex & secure cloud solutions, I have built expertise in
                identifying business needs, articulating & facilitating change, and finding & delivering innovative &
                successful solutions that maximize the value delivered to the clients. Whether conceptualizing a new
                product or iterating over an existing one, I am passionate about driving critical initiatives that will
                fuel your ability to innovate, move quickly, and stay ahead of the competition."
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
