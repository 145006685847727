import React from 'react';
import { Link } from 'react-router-dom';
import SocialIcons from '../SocialIcons/SocialIcons';
import './MobileFooter.scss';

const MobileFooter = () => {
  return (
    <div className="MobileFooter">
      <div className="footerDiv">
        <SocialIcons />
      </div>
      <ul className="MobileFooterContainer">
        <li>
          {/* <div className="icon">
            <Link to="/aboutme">
              <i className="fas fa-female"></i>
            </Link>
          </div> */}
          <div className="link">
            <Link to="/">Home</Link>
          </div>
        </li>

        <li>
          <div>
            <p>@ Chii Nwachukwu</p>
          </div>
        </li>

        <li>
          {/* <div className="icon">
            <Link to="/portfolio">
              <i className="fa fa-cogs"></i>
            </Link>
          </div> */}
          <div className="link">
            <Link to="/portfolio">Portfolio</Link>
          </div>
        </li>

        {/* <li>
          <div className="icon">
            <Link to="/contactme">
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <div className="link">
            <Link to="/contactme">Contact me</Link>
          </div>
        </li> */}
      </ul>
    </div>
  );
};

export default MobileFooter;
