import React from 'react';
import './StoryCards.scss';

const StoryCard = (props) => {
  console.log(props);
  const { stage, icon, note } = props;
  return (
    <div className="StoryCard">
      <div className="storyCardStageContainer">
        <div className="storyCardStage">
          <p>{stage}</p>
        </div>
      </div>
      <div className="storyCardIconConatiner">
        <i className={icon}></i>
      </div>
      <div className="storyCardParagraphConatiner">
        <p>{note}</p>
      </div>
    </div>
  );
};

export default StoryCard;
