import React from 'react';
import './ExperienceCard.scss';

const ExperienceCard = (props) => {
  const { title, position, description } = props.props;
  return (
    <div className="ExperienceCard">
      <div className="ExperienceCardHeader">
        <h2>{title}</h2>
      </div>
      <div className="ExperienceCardPositionTimeline">
        <h3>{position}</h3>
      </div>
      <div className="ExperienceCardDescription">
        <ul>
          {description.map((describe, index) => {
            return <li key={index}>{describe}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default ExperienceCard;
