import React from 'react';
import { Link } from 'react-router-dom';
import './PortfolioPages.scss';

const PortfolioPages = () => {
  const Data = ['Portfolio', 'Applications'];

  return (
    <div className="PortfolioPages">
      <div className="PortfolioPagesContainer">
        {Data.map((project, index) => {
          const replaced = project.replaceAll(/[^A-Z0-9]+/gi, '');
          return (
            <div className="ProjectBox" key={index}>
              <div className="ProjectName" key={index}>
                <Link to={`/category/${replaced}`}>{project}</Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PortfolioPages;
