export default [
  {
    title: 'Anchore Inc.',
    position: 'Associate Support Engineer',
    timeline: 'August 2020 - Current',
    description: [
      'Experience with container orchestration tools such as Kubernetes and Docker',
      'Deployed and managed applications using AWS',
      'Understanding and experience working in Windows and Linux environments',
      'Working closely with Program Management, Engineering, and Devops to communicate high-priority client issues',
      'Daily operational support and frequent engagement with clients in Zendesk',
      'Triaging, troubleshooting & debugging connectivity issues & configuration problems',
      "Providing resources to answer clients' questions, identifying needs for new features where applicable",
      'Excellent presentation and communication skills to technical and non-technical audiences',
      'Ability to learn new frameworks, design patterns, architecture and programming languages',
      'Ability to adapt to new and changing environments.',
    ],
  },
  {
    title: 'Service Robotics and Technologies',
    position: 'Front-end Development Intern',
    timeline: 'April 2020 - August 2020',
    description: [
      'Collaborate with back-end, design, research, and business development teams to develop an application, which will map a facility in 3D and code a platform for multi-robot integration, localization, and data sharing within a facility.',
      'Develop, design and build new features that pull back-end raw data and render them accordingly using React JS, Redux for state management, and Sass (CSS preprocessor) for styling.',
      'Tasks include, but are not limited to: analytics, webhooks, form design, redesigning current components, and debugging.',
      'Create python mini-applications to communicate with, give commands to and pull data from robots.',
      'Hands on experience designing and deploying complex solutions on-premise and in the cloud',
    ],
  },
  {
    title: 'GENERAL ASSEMBLY DC',
    position: 'Software Engineering Immersive Student ',
    timeline: 'Sept. 2019​ - Dec. 2019',
    description: [
      'Collaborated with a team of over 25 software engineers and UI/UX designers on the design, implementation, testing, and documentation of client projects in an agile environment.',
      'Designed and developed functional and aesthetically pleasing user-interface using technologies such as HTML, CSS, Javascript, and React.JS.',
      'Developed back-end language and framework proficiency with Python, Node.js, Express.js, and Django.',
      'Utilized multiple database technologies including relational and non-relational databases.',
      'Utilized a hands-on approach to develop real-world applications while maintaining two GitHub (GitHub & GH Enterprise) profiles with a combined commit history of 400+ contributions.',
    ],
  },
  {
    title: 'FiscalNote, Washington, DC',
    position: 'Front Desk Assistant',
    timeline: 'Nov. 2018​ - Aug. 2019​',
    description: [
      'Enhanced the company’s integrity and security by designing and implementing the use of an identification badge system.',
      "Provided excellent customer service by creating a client-facing area conducive enough for employees to perform at their optimum best and relaxing enough for the client's comfort.",
      'Managed the first line of communication between the company and prospective clients while maintaining a sense of urgency and excellent organizational skills.',
    ],
  },
];
