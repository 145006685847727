import React from 'react';
import FooterAnimation from '../FooterAnimation/FooterAnimation';
import './Footer.scss';

const Footer = () => {
  return (
    <div className="footerDivContainer">
      <FooterAnimation />
    </div>
  );
};

export default Footer;
