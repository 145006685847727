import React from 'react';
import './About.scss';
import StoryCard from '../../../../components/StoryCards/StoryCards';
import Portfolio from '../PortfolioPage/Portfolio';
import Footer from '../../../../components/Footer/Footer';
import image from '../../../../util/images/unnamed.jpeg';

const AboutPage = () => {
  return (
    <div className="AboutPage" id="about">
      <div className="AboutPageContainer">
        <div className="AboutPageImage">
          <div className="ImageContainer">
            <img src={image} alt="Chiebuka Nwachukwu" />
          </div>
        </div>
        <div className="aboutheaderContainer">
          <div className="aboutheader">
            <h1>Chiebuka Nwachukwu</h1>
          </div>
          <div className="aboutheaderParagraph">
            <p>
              Hi! My name is Chiebuka Nwachukwu but call me Chii. Welcome to my journey of pursuing my passion for
              helping businesses discover and deliver innovative and successful products that meet the customers' needs
              in a way that is better than the competition. Since my background in scientific research and support
              engineering is a little unusual, I created these cards to tell you a little bit about myself.
            </p>
          </div>
        </div>
        <div className="aboutStoryCardContainer">
          <StoryCard
            stage="1"
            icon="far fa-user storyIcon"
            note="Curiosity led me to my first python project and gave me all the push I needed to enroll in the Software Engineering Immersive Program at General Assembly DC. Next, I joined Service Robotics & Technologies as a front-end development intern, and after my internship ended, I started at Anchore, Inc as a Support Engineer. Currently, I work with the research and development team at Regeneron. My work primarily focuses on leading integration efforts on our in-house services platform that facilitates data movement across multiple third-party enterprise systems."
          />

          {/* <StoryCard
            stage="2"
            icon="fab fa-python storyIcon"
            note="In pursuing my passion for helping businesses curate innovative and phenomenal products and projects, I have built expertise in delivering successful and sustainable products that meet business goals and guarantee impeccable customer experiences. I intend to use this portfolio to document my journey and share everything I learn."
          /> */}

          {/* <StoryCard
            stage="3"
            icon="fas fa-server storyIcon"
            note="In pursuing my passion for helping businesses curate phenomenal products and projects, I have built expertise in delivering successful and sustainable products that meet your business goals and guarantee impeccable customer experiences. I intend to use this portfolio to document my journey and share everything I learn."
          /> */}
          <StoryCard
            stage="2"
            icon="fas fa-server storyIcon"
            note="In pursuing my passion,  I am building expertise in delivering successful and sustainable products that customers love, value, and want over existing options. Whether conceptualizing a new product or iterating over an existing one, I am passionate about driving critical initiatives that will fuel your ability to innovate, move quickly, and stay ahead of the competition. I intend to use this portfolio to document my journey and share everything I learn. If I could name my superpower, it would be my ability to figure out solutions amid chaos. In addition to growing my technical skills, I'm learning how to paint. Oh! I also want to visit Croatia! "
          />
        </div>

        <div className="aboutRecommendationsContainer">
          <div className="header">
            <h2>My Recommendations:</h2>
          </div>
          <div className="aboutRecommendationslistContainer">
            <div className="aboutRecommendationslist">
              <h3>Books</h3>
              <ul>
                <li>
                  <a
                    href="https://www.amazon.com/INSPIRED-Create-Tech-Products-Customers/dp/1119387507"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Inspired
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.amazon.com/Lean-Product-Playbook-Innovate-Products/dp/1118960874/ref=pd_lpo_2?pd_rd_w=a9eSh&content-id=amzn1.sym.116f529c-aa4d-4763-b2b6-4d614ec7dc00&pf_rd_p=116f529c-aa4d-4763-b2b6-4d614ec7dc00&pf_rd_r=EJHDQ4DYVWGJJB7QWW9G&pd_rd_wg=1vD1w&pd_rd_r=739de892-6f13-42cc-a2f8-acc1de8cd766&pd_rd_i=1118960874&psc=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Lean Product Playbook
                  </a>
                </li>
              </ul>
            </div>
            <div className="aboutRecommendationslist">
              <h3>Podcasts</h3>
              <ul>
                <li>
                  <a
                    href="https://podcasts.apple.com/us/podcast/product-thinking/id1550800132"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Product Thinking by Melissa Perri
                  </a>
                </li>
                <li>
                  <a
                    href="https://podcasts.apple.com/us/podcast/this-is-product-management/id975284403"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    This is Product Management by Feedback Loop
                  </a>
                </li>
              </ul>
            </div>
            <div className="aboutRecommendationslist">
              <h3>Something I'm learning</h3>
              <ul>
                <li>
                  <a href="https://kubernetes.io/" target="_blank" rel="noopener noreferrer">
                    Kubernetes
                  </a>
                </li>
                <li>
                  <a href="https://medium.com/@cenwachukwuu" target="_blank" rel="noopener noreferrer">
                    Find more on my medium page
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Portfolio />
      <Footer />
    </div>
  );
};

export default AboutPage;
