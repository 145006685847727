import React from 'react';
import './Contact.scss';
import Portfolio from '../home/HomeViews/PortfolioPage/Portfolio';
import Footer from '../../components/Footer/Footer';
import Contactme from '../../components/ContactMeCard/ContactMe';

const Contact = () => {
  return (
    <div className="Contact">
      <div className="ContactContainer ">
        <div className="contactHeaderContainer">
          <div className="contactHeader">
            <h1>Let us keep in touch</h1>
          </div>
          <div className="contactHeaderQoute">
            <p>
              The most important things in life are the connections you make with others <span>~</span>Tom Ford
            </p>
          </div>
        </div>
        <Contactme />
      </div>
      <Portfolio />
      <Footer />
    </div>
  );
};

export default Contact;
