import React from 'react';
import './SkillsCard.scss';

const SkillsCard = (props) => {
  const { icon, name } = props;
  return (
    <div className="SkillsCard">
      <div className="skillsIcon">
        <i className={icon}></i>
      </div>
      <div className="skillName">
        <h2>{name}</h2>
      </div>
    </div>
  );
};

export default SkillsCard;
